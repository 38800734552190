#block-bean-baner-aktualnosci{
    h2.title{
        display:none;
    }
    img{
        width:100%;
        height:auto;
    }
}

.view-id-news{
    &.view-display-id-page{
       
        @include span(12 break);
        .views-row{
            padding:20px 20px;
            @include susy-media($medium-up){
                 padding:60px 0px;
            }
            @include span(12 break);
            &.views-row-odd{
                background-color:$color-grey-light;
            } 
        
        }
        .views-field-field-data{
            @include span(12 of12 break);
            @include susy-media($medium-up){
              @include span(isolate 10 at 2 of 12 break);  
            }
            padding:0 0 10px 0;
            @include susy-media($xmedium-up){
                
                @include span(isolate 2 at 2 of 12 nobreak);
                padding:0;
                font-size:$font-size-lessextra;
                text-align:right;
                color:$color-blue;
            }
            color:#000;
            
        }
        .views-field-nothing{
            @include span(12  of 12 break);
          
            @include susy-media($medium-up){
                @include span(isolate 10 at 2 of 12 break);
            }
            @include susy-media($xmedium-up){
                padding:0;
                @include span(isolate 7 at 5 of 12 nobreak);
               
            }
            .news-title{
                font-size:$font-size-bigger;
                @include susy-media($medium-up){
                    font-size:$font-size-morethanbigger;
                }
                color:$color-blue;
            }
        }
    }
}


#block-views-news-block, #block-views-news-block--2
    {
        position:relative;
        padding:20px 20px;
        @include span(12 of 12 break);
        @include susy-media($medium-up){
          padding:80px 20px;
            @include span(isolate 8 at 3 of 12 break);    
            padding:80px 10px;
        }
        
       
        h2.title{
            margin-top:0;
            color:$color-blue;
            font-family:$font-primary;
            font-weight:$light;
            text-align:center;
             @include susy-media($large-up){
                            font-size:$font-size-extra;
                        }
        }
        #views_slideshow_cycle_main_news-block{
            @include span(12 of 12 break);
            padding:0 30px;
            @include susy-media($medium-up){
                @include span (isolate 8 at 3 of 12);
                padding:0;
            }
        }
        #vscc_controls_previous_news-block{
                  position:absolute;
                  top:60%;
                  left:0;
                
                 
                 
        }
        #views_slideshow_cycle_teaser_section_news-block{
            width:100% !important;
            .views-slideshow-cycle-main-frame-row{
                width:100% !important;
                .views-field-created{
                    font-size:$font-size-normal;
                    color:$color-blue;
                }
                .views-field-title a{
                    padding-top:10px;
                    display:block;
                    color:#000;
                    font-size:$font-size-default;
                    font-weight:700;
                    text-decoration: none;
                }
                .views-field-body{
                    color:#000;
                    font-size:$font-size-normal;
                    text-align:justify;
                    @include susy-media($medium-up){
                        text-align:left;
                        font-size:$font-size-default;
                    }
                }
            }
            
        }
        .views-slideshow-controls-top{
        position:relative;
          /* display:none;*/
         #vscc_controls_previous_news-block {
              position:absolute;
              top:250%;
              @include susy-media($medium-up){
                  top:50%;
              }
              
              a{
                  img{
                     display:none;
                  }
                  margin-top:80px;
                  display: block;
                  width: 15px;
                  height: 30px; 
                  
                  @include susy-media($medium-up){
                    width: 50px;
                    height: 100px;
                  }
                  
                  background-size:contain;
                  background: url(../images/news-arrow-left.png) no-repeat left center #fff
             }
         }
         #vscc_controls_next_news-block{
             a{
                 img{
                     display:none;
                 }
                
                display: block;
                width: 15px;
                height: 30px;
                @include susy-media($medium-up){
                    width: 50px;
                    height: 100px;
                }
                margin-top:80px;
                background-size: contain;
                background: url(../images/news-arrow-right.png) no-repeat right center #fff;
             }
              position:absolute;
              top:250%;
              @include susy-media($medium-up){
                  top:50%;
              }
              right:0; 
              a{ 
                  text-indent:-9000px;
               }
         }
        }
        #vscc_controls_next_news-block{
                
           
        }
        .views-field-field-data{
            color:$color-blue;
        }
        .more-link{
            
            padding-top:20px;
            margin-bottom:20px;
            @include susy-media($medium-up)
            {
              padding-top:50px;
            }
            @include span(12 break);
            text-align:center;
            a{
                border:1px solid $color-blue;
                color:$color-blue;
                border-radius:5px;
                text-decoration:none;
                padding: 10px 20px;
            }
        }
    }
