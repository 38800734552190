body{
  background:#fff;
  font-family:$font-primary;
}


nav.tabs, .drupal-messages{
    @include pre(1);
    @include post(1);
    @include span(10 of 12 break);
}
* {
  box-sizing: border-box;
}

p{
    line-height:150%; 
    font-size:$font-size-small;
    @include susy-media($xsmall-up){
        line-height:150%; 
    }
    @include susy-media($small-up){
         font-size:$font-size-normal;
        line-height:150%; 
    }
    @include susy-media($medium-up){
        font-size:$font-size-default;
        line-height:170%; 
    }
    @include susy-media($xmedium-up){
        font-size:$font-size-bigger;
        line-height:170%; 
    }
    @include susy-media($large-up){
        line-height:180%; 
    }
}
.header-region{
    .block-bean{
        width:100%;
        img{
            width:100%; 
            height:auto;
        }
    }
    
}
label{
    display:none;
}
 .page{
    @include container;
    @include susy-media($medium-up) {  }
    @include susy-media($large-up) { }
    @include susy-media($xlarge-up) {}
    @include susy-media($xxlarge-up) {}
    @include susy-media($xxxlarge-up) {}
 }
header{
    width:100%;
    #top-wrapper{
      
        @include container;
        
        background-color:#fff;
        #logo-wrapper{
            figure{
                margin: 1em;
            }
            padding:5px 0;
          
            @include span(isolate 5 at 1 of 12);
            @include susy-media($xlarge-up){
                @include span(isolate 2 at 1 of 12);
            }
            img{
                padding-left:gutter()*2;
                display:block;
                max-height:100%;
                max-width:100%;
            }
        }
        #lang-wrapper-lg{
            display:none;
            @include susy-media($large-up){
                display:block;
                @include span(isolate 2 at 11 of 12);
            }
            
            margin-top:1.5em;
        }
        #menu-wrapper{
            text-align:right;
            @include span(isolate 8 at 3 of 12);
            margin-top:1.5em;
            
            
            
            @include susy-media($medium-up){
                margin-top:2em;
            }
            @include susy-media($large-up){
                @include span(isolate 8 at 3 of 12);
                margin-top:3em;
            } 
          
            ul{
                margin:0;
                padding:0;
                list-style:none;
            }
        }
    }
   
}
#main-content{
    
    #block-bean-kim-jestesmy, #block-bean-who-we-are, #block-bean-wer-sind-wir{
        padding:40px 0;
        padding-bottom:0;
        @include span(12 of 12 break);
        h2.title{
            color:$color-blue;
            /*font-family:$font-primary;*/
            font-weight:$light; 
            margin-top:0;
            font-family:"exo1";
            font-size:$font-size-lessextra;
            @include susy-media($large-up){
                font-size:$font-size-extra;
            }
        }
        .field-name-field-node-reference {
            padding-top:20px;
            padding-bottom:20px;
            @include span(12 break);
            @include susy-media($medium-up){
                margin:30px;
            }
            text-align:center;
            a{
                border:1px solid $color-blue;
                color:$color-blue;
                border-radius:5px;
                text-decoration:none;
                padding:5px 10px;
            }
        }
        .block-content{
            font-size:$font-size-normal;
            padding-bottom:40px;
            font-family:"exo1";
            @include span(12 of 12 break);
            padding:0 20px 40px 20px;
            @include susy-media($medium-up){ 
                font-size:$font-size-default;
                @include span(isolate 9 at 3 of 12);
                padding-bottom:0;
            }
            @include susy-media($large-up) { @include span(isolate  6 at 4 of 12);}
            @include susy-media($xlarge-up) { } 
            @include susy-media($xxlarge-up) { }
            @include susy-media($xxxlarge-up) { }
            text-align:center;
            
        }
    }
    #block-bean-uslugi-it-front-page, #block-bean-it-services, #block-bean-it-dienstleistungen{
        
        @include span(12 of 12 break);
        .block-content-wrapper{
            background-image:none;
            @include susy-media($xlarge-up){
                background-image:url('../images/uslugi_foto.jpg');
            }
            background-size: auto auto;
            background-repeat:no-repeat;
            background-position: center right;
            width:100%;
        
             .block-content{
                @include span(12 nested); 
                height:100%;
                
                padding:20px 0px 10px 0px;
                @include susy-media($small-up){ 
                    padding:20px 20px 20px 20px;
                }
                @include susy-media($medium-up){
                     min-height:650px;
                     padding:40px 0px 20px 0px;
                }
                float:none;
                width:100%;
                background-size: 100% auto;
                min-height:600px;
                background:$color-blue;
                background-image:url('../images/uslugi_bg.jpg');
                background-repeat:no-repeat;
                @include susy-media($xlarge-up){
                     width:50%;
                     background-size: 100% auto;
                     background-position: right center;
                     min-height:700px;
                }
               > .entity-bean{
                   .content:after{
                       clear:both;
                       display:block;
                       content:'';
                   }
                   p{margin-top:0;}
                   /* min-height:684px;*/
                    color:#fff;
                    font-family:$font-primary;
                    font-weight:$light; 
                    
                    font-size:$font-size-normal;
                    @include pre(1);
                    @include post(1);
                    @include span(10 of 12 break);
                    @include susy-media($medium-up){
                        font-size:$font-size-default;
                        @include span(isolate 4 at 2 of 6 break);
                    }
                    @include susy-media($xmedium-up){
                        font-size:$font-size-default;
                    }
                  
                    .field-type-entityreference{
                        .field-item{
                            text-align:center;
                            text-transform:uppercase;
                            margin-top:50px;
                            @include span(4 of 12);
                            .field-type-image{
                                padding-bottom:10px;
                                img{
                                    display:block;
                                    margin:0 auto;
                                }
                            }
                          
                        }
                        .field-item:nth-child(3n){
                            @include span(4 of 12 last);
                        }
                        
                    }
                    .field-name-field-etykieta{
                        font-weight:normal;
                        line-height:130%;
                        text-align:center;
                        padding:5px;
                        font-size:$font-size-normal - 3; 
                        @include susy-media($small-up){font-size:$font-size-normal - 1;}
                        @include susy-media($medium-up){font-weight:700; font-size:$font-size-normal;}
                    }
                    
                }
                h2.title{
                    color:#fff;
                    margin: 10px 0 20px 0;
                    text-align:center;
                    font-family:$font-primary;
                    font-weight:$light;
                    @include span(isolate 10 at 2 of 12 break);
                    font-size:$font-size-lessextra;
                    @include susy-media($large-up){
                        font-size:$font-size-extra;
                    }
                }
                .field-name-field-node-reference {
                    margin-top:40px;
                    @include span(12 break);
                    text-align:center;
                    &:after{
                        clear:both;
                        display:block;
                        margin-bottom:20px;
                    }
                    a{
                        border:1px solid #fff;
                        color:#fff;
                        border-radius:5px;
                        text-decoration:none;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
    #block-bean-szukasz-pracy-front-page, #block-bean-looking-for-a-job, #block-bean-bist-du-auf-der-arbeitssuche{
        @include span(12 of 12 break);
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url('../images/praca_foto.jpg');
                background-size: auto auto;
                background-repeat:no-repeat;
                background-position: left center;
         
            }
             width:100%;
             .block-content{
                 padding:20px 0;
                 background-color:#fff;
                 width:100%;
                
                 @include susy-media($xlarge-up){
                     width:50%;
                     margin-left:50%;
                     background-position: right center;
                     min-height:413px;
                 }
                 .entity-bean{
                    color:#000; 
                    font-family:$font-primary;
                    font-weight:$light; 
                    text-align:center;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($medium-up){
                        @include span(isolate 8 at 3 of 12 break);
                    }
                    @include susy-media($large-up){
                         text-align:left;    
                        @include span(isolate 10 at 2 of 12 break);
                    }
                    @include susy-media($xlarge-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                    @include susy-media($xxlarge-up){
                      font-size:$font-size-default; 
                    }
                    
                }
                 h2.title{
                        color:$color-blue;
                        font-family:$font-primary;
                        font-weight:$light;
                        margin-bottom:10px;
                        text-align:center;
                        @include span(isolate 10 at 2 of 12 break);
                        @include susy-media($medium-up){
                            @include span(isolate 8 at 3 of 12 break);
                        }
                        font-size:$font-size-lessextra;
                        @include susy-media($large-up){
                            
                            text-align:left;
                            font-size:$font-size-extra;
                           @include span(isolate 10 at 2 of 12 break);
                        }
                 }
                 p{
                     margin-top:0;
                 }
                 .field-name-field-node-reference {
                    margin-top:30px;
                    margin-bottom:40px;
                    @include susy-media($large-up){
                        padding-bottom:0;
                    }
                    @include span(12 break);
                    text-align:center;
                    a{
                        border:1px solid $color-blue;
                        color:$color-blue;
                        border-radius:5px;
                        text-decoration:none;
                        padding: 10px 20px;
                        &:after{
                            clear:both;
                            content:'';
                        }   
                    }
                    &:after{
                        clear:both;
                        content:'';
                    }
                }
            }
        }
    }
   
        #block-bean-front-before-aktualnosci, #block-bean-pp-solutions-innovation, #block-bean-pp-solutions-entwicklung-und-inn{
            
             @include span(12 of 12 break);
             background-color:$color-blue;
              .block-content-wrapper{
                  
                  
                  color:#fff;
                  @include span(isolate 10 at 2 of 12 );
                  padding:10px 0;
                  font-size:$font-size-small;
                  @include susy-media($small-up){
                    padding:20px 0;
                    font-size:$font-size-small;
                  }
                  @include susy-media($xsmall-up){
                     font-size:$font-size-normal;
                  }
                  
                  @include susy-media($medium-up){
                      font-size:$font-default;
                       padding:40px 0;
                       @include span(isolate  8 at 3 of 12 );
                  }
                       
                  @include susy-media($large-up) { 
                      padding:80px 0;
                      @include span(isolate  6 at 4 of 12);
                  }
                  
                  @include susy-media($xlarge-up) { } 
                  @include susy-media($xxlarge-up) { }
                  @include susy-media($xxxlarge-up) { }
                  text-align:center;
              }
             .field-content{
                 color:$color-blue;
             }
          
        }

  
}


/*language switcher*/

#language-switcher-locale-url{
    list-style:none;
    display:inline; 
}
#lang-wrapper-lg{
    ul{
        margin:0;
        padding-top:22px;

    li{
        display:inline;
        list-style:none;
        a{
            text-decoration:none;
            color:#333;
             &.active{
                    font-weight: bold;
             }
        }
    }
    } 

}
#lang-wrapper-mobile{
    background: $color-blue;
    @include container;
    @include susy-media($large-up){
        display:none;
    }
    .region{
        @include span(10 of 12 break);
        @include pre(1);
        @inlcude post(1);
        ul{
            text-align:right;
            margin:0;
            padding: 5px 0;
        }
        li{
            display:inline;
            list-style:none;
            a{
                text-decoration:none;
                color:#fff;
                &.active{
                    font-weight: bold;
                }
            }
        }
    }
}

ul#rm-removed{
    li{
     padding-left:0 !important;  
    }
}

