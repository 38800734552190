body.page-jobs{
    #block-bean-aktualne-br-oferty-pracy{
        @include banner();
    }
}
    .view-oferty{
        @include span(12 break);
            article{
                @include span(12 break);
                padding:80px 0;
                &.views-row-even{
                    background:#f0f0f0;
                }
                .views-field-title{
                    color:$color-blue;
                    text-align:center;
                    font-size:$font-size-lessextra;
                    padding-bottom:20px;
                    @include span(isolate 10 at 2 of 12 break)
                    @include susy-media($large-up){
                        text-align:left;
                        padding-bottom:0;
                        font-size:$font-size-lessextra;
                        @include span(isolate 3 at 2 of 12 nobreak)
                    }
                }
                .views-field-nothing{
                    a{
                        color:$color-blue;
                        display:block-inline;
                        border:1px solid $color-blue;
                        padding:5px 10px;
                        border-radius:5px;
                        text-decoration:none;
                    }
                    text-align:center;
                    @include span(isolate 10 at 2 of 12 break)
                    @include susy-media($large-up){
                        text-align:right;
                        @include span(isolate 3 at 8 of 12 nobreak)
                    }
                }
                .views-field-nothing-1{
                    @include span(isolate 10 at 2 of 12 break);
                     @include susy-media($large-up){
                          @include pre(1);
                          @include post(1);
                          @include span(isolate 10  of 12 break);
                     }
                    display:none;
                    .links{
                        margin:20px 0;
                        @include span(12 break);
                        
                        .link-aplikuj{
                          
                            @include span(6 of 12);
                               a{
                                    color:$color-blue;
                                    display:block-inline;
                                    border:1px solid $color-blue;
                                    padding:5px 10px;
                                    border-radius:5px;
                                    text-decoration:none;
                                }
                        }
                        .link-ukryj{
                          text-align:right;
                            @include span(6 of 12 last);
                               a{
                                    color:$color-blue;
                                    display:block-inline;
                                    border:1px solid $color-blue;
                                    padding:5px 10px;
                                    border-radius:5px;
                                    text-decoration:none;
                                }
                        }
                    }
                    .details1{
                        @include span(4 of 12 first);
                    }
                    .details2{
                        @include span(4 of 12);
                    }
                    .details3{
                        @include span(4 of 12 last);
                    }
                }
                ul{
                    li{
                        font-size:$font-size-normal;
                        margin:8px 0;
                        line-height:140%;
                    }
                }
                .classified-body{
                    margin:10px 0;
                    p{
                       margin:3px 0;
                       font-size:$font-size-default;
                       line-height:140%;
                    }
                }
            
            }
    }
.entitytype-formularz_aplikacyjny-form{
    @include pre(1);
    @include post(1);
    @include span(10 break);
    .marker, .form-required{
        color:#333;
    }
    
        input[type="text"]{
            border-radius:5px;
            padding:10px;
            line-height:140%;
            border:1px solid #ccc;
            &::placeholder{
                color:#333;
            }
        }
        select{
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -webkit-padding-end: 20px;
                -moz-padding-end: 20px;
                -webkit-padding-start: 2px;
                -moz-padding-start: 2px;
                background-color: #fff; /* fallback color if gradients are not supported */
                //background-image: url(../images/select-arrow.png), -webkit-linear-gradient(top, #E5E5E5, #F4F4F4); /* For Chrome and Safari */
                //background-image: url(../images/select-arrow.png), -moz-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Fx (3.6 to 15) */
                //background-image: url(../images/select-arrow.png), -ms-linear-gradient(top, #E5E5E5, #F4F4F4); /* For pre-releases of IE 10*/
                //background-image: url(../images/select-arrow.png), -o-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Opera (11.1 to 12.0) */ 
                //background-image: url(../images/select-arrow.png), linear-gradient(to bottom, #E5E5E5, #F4F4F4); /* Standard syntax; must be last */
                background-image: url(../images/select-arrow.png);
                background-image: url(../images/select-arrow.png);
                background-image: url(../images/select-arrow.png);
                background-image: url(../images/select-arrow.png);
                background-image: url(../images/select-arrow.png);
                background-position: center right 5px;
                background-repeat: no-repeat;
                border: 1px solid #AAA;
                border-radius: 2px;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
                color: #555;
                font-size: inherit;
                margin: 0;
                overflow: hidden;
                padding-top: 2px;
                padding-right:40px;
                padding-bottom: 2px;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height:140%;
                option{
                    padding:5px; 
                }
        }
        .form-item-field-zgoda-na-przetwarzanie-dan-und{
            .description{
                display:inline-block;    
            }
            
        }
}
