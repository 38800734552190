#block-bean-baner-oferta-dla-kandydatow{
    h2.title{
        display:none;
    }
    .field-type-text{
        font-size:$font-size-bigger;
         @include susy-media($small-up){
            font-size:$font-size-medium;
        }
        @include susy-media($medium-up){
            font-size:$font-size-lessextra;
        }
         @include susy-media($large-up){
            font-size:$font-size-extraxl;
        }
    } 
    @include span(12 break);
    img{
        width:100%;
        height:auto;
    }
}
#block-bean-dla-kandydatow-wstep, #block-bean-for-candidates, #block-bean-fur-kandidaten-intro-cotnent{
    @include span(12 break);
    .block-content{
        
        
        @include span(isolate 10 at 2 of 12);
         padding:15px 0;
        @include susy-media($small-up){
            padding:20px 0;
        }
        @include susy-media($medium-up){
            padding:30px 0;
        }
        @include susy-media($large-up){
            padding:40px 0;
        }
        @include susy-media($xxlarge-up){
            padding:80px 0;
            background:url(../images/emp/opis_foto.jpg) no-repeat left top;
            background-size:35% auto;
        }
    }
    .content{
          @include span(isolate 10 of 10 break);
          @include susy-media($xxlarge-up){
            @include span(isolate 6 at 5 of 10 break);
          }
    }
} 
#block-bean-kandydaci-modele-wspolpracy, #block-bean-cooperation-models, #block-bean-kooperationsmodelle{
    @include span(12 break);
    background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/emp/wspolpraca_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right -200px center;
            }
             @include susy-media($xlarge-up){
                   background-position: right -150px center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                 
                 @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                 }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/emp/wspolpraca_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
               
                 }
                 
            }
        }
}

#block-bean-modele-wspolpracy-tekst, #block-bean-cooperation-models-content, #block-bean-kooperationsmodelle-content-0{
    padding-top:15px;
    padding-bottom:15px;
    @include susy-media($small-up){
        padding-top:20px;
        padding-bottom:20px;
    }
    @include susy-media($medium-up){
        padding-top:30px;
        padding-bottom:30px;
    }
    @include susy-media($large-up){
        padding-top:40px;
        padding-bottom:40px;
    }
    @include susy-media($xlarge-up){
        padding-top:80px;
         padding-bottom:40px;
    }
    background-color:#f0f0f0;
    @include span(12 break);
    .entity-bean>.content>.field-type-text-long{
        text-align:center;
       
        @include span(isolate 10 at 2 of 12 break);
        @include susy-media($medium-up){
            @include span(isolate 6 at 4 of 12 break);
        }
       
    }
    .field-type-entityreference{
        @include span(isolate 8 at 3 of 12 break nested);
        .field-items{
            .field-item{
                @include span(12 break);
                @include susy-media($xmedium-up){ 
                    @include span(2 of 8 nobreak);
                    &:nth-child(4){
                        @include span(2 of 8 nobreak last);
                    }
                }
           }
            .entity-bean>.content{ 
                    width:100%;
                    @include susy-media($xmedium-up){
                        max-width:169px;
                        background:url(../images/emp/umowa_icon.png) no-repeat top center;   
                        background-size:100% auto;
                    }
                   
                    @include susy-media($large-up){
                        background:url(../images/emp/umowa_icon.png) no-repeat top center;   
                        background-size:100% auto;
                    }
                    
                 .field-type-text-long{
                    float:none;
                    p{
                        
                        font-size:$font-size-bigger;
                    }
                    @include susy-media($xmedium-up){
                        height:150px;
                        p{
                            font-size:$font-size-default;
                        }
                        
                        max-width:110px;
                        padding-top:20px;
                    }
                    font-size:$font-size-small;
                    @include susy-media($medium-up)
                    {
                        font-size:$font-size-bigger;
                        height:219px;
                        padding:0 14px;
                         height:150px;
                        max-width:110px;
                        padding-top:50px;
                    }
                    @include susy-media($large-up)
                    {
                        font-size:$font-size-bigger;
                        height:219px;
                        padding:0 14px;
                        max-width:164px;
                        padding-top:60px;
                    }
                    margin:0 auto;
                    color:$color-blue;
                 }
                 
            }
        }
    }   
        
}
#block-bean-dla-kandydatow-modele-additi, #block-bean-candidates-cooperation-content, #block-bean-kooperationsmodelle-content-afte{
    @include span(12 break);
    
    background-color:#f0f0f0;
    .content{
        padding-bottom:20px;
        text-align:center;
        font-size:$font-size-normal;
        @include span(isolate 10 at 2 of 12 break);
        @include susy-media($medium-up){
            padding-bottom:40px;
            @include span(isolate 6 at 4 of 12 break);
        }
        @include susy-media($medium-up){
            padding-bottom:80px;
        }
    }
}

#block-bean-dla-kandydatow-benefity-nagl, #block-bean-benefits,#block-bean-vorteile-header {
     @include span(12 break);
     background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/emp/benefity_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right -200px center;
            }
             @include susy-media($xlarge-up){
                   background-position: right -150px center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/emp/benefity_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
               }
                 
            }
        }
}
#block-bean-benefity-header-before-icons, #block-bean-benefits-text-before-icons, {
    padding-top:20px;
    @include span(12 break);
    padding-bottom:10px;
    .content{
       text-align:center;
        font-size:$font-size-normal;
        @include span(isolate 10 at 2 of 12 break);
        @include susy-media($medium-up){
            padding-top:40px;
             padding-bottom:40px;
            @include span(isolate 6 at 4 of 12 break);
        }
        @include susy-media($large-up){
            padding-top:80px;
        }
    }
}
#block-bean-vorteile-icons
    {
        .field-name-field-zawarto-{
            text-align:center;
            padding-bottom:20px;
        }
    }
#block-bean-dla-kandydatow-benefity-teks, #block-bean-benefits-icons, #block-bean-vorteile-icons
{
    padding-top:20px;
    @include susy-media($medium-up){
            padding-top:20px;
    }
    @include susy-media($large-up){
        padding-top:20px;
    }
    h2{
        font-size:$font-size-bigger;
        font-weight:400;
        font-family:$font-primary;
        padding-bottom:40px;
    }
    @include span(12 break);
    .block-content-wrapper{
          @include span(isolate 10 at 2 of 12 break);
          .field-type-entityreference{
              .field-item{
                  text-align:center;
                      @include span(3 of 9);
                      margin-bottom:30px;
                      font-size:$font-size-small;
                       @include susy-media(medium-up){
                           font-size:$font-size-normal;
                       }
                       @include susy-media($xlarge-up){
                            @include span(1 of 9);
                            font-size:$font-size-default;
                       }
                       &:nth-of-type(3n){
                          @include span(3 of 9 last);
                          @include susy-media($xlarge-up){
                              @include span(1 of 9);
                              margin-bottom:0;
                          }
                       }
                      
                      
                      &:last-child{
                            @include susy-media($xlarge-up){
                                @include span(1 of 9 last);
                            }
                      }
                   .field-name-field-etykieta {
                       padding:10px;
                       line-height:130%;
                   }   
              }
              
          }
    }
}
#block-bean-dla-kandydatow-czas-wolny-na, #block-bean-leisure-time-header, #block-bean-freizeit-header{
      @include span(12 break);
      background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/emp/czaswolny_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             @include susy-media($xlarge-up){
                   background-position: right  center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                 
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/emp/czaswolny_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                    }
                 
            }
        }
}

#block-bean-dla-kandydatow-rekrutacja-na, #block-bean-recruitment, #block-bean-der-rekrutierungsprozess-heade{
     @include span(12 break);
     background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/emp/rekrutacja_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             @include susy-media($xlarge-up){
                   background-position: right  center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/emp/rekrutacja_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                    }
                 
            }
        }
}
#block-bean-dla-kandydatow-czas-wolny-te, #block-bean-dla-kandydatow-czas-wolny-0, #block-bean-leasure-time-intro-content, #block-bean-recruitment-intro-content,
#block-bean-freizeit-into, #block-bean-der-rekrutierungsprozess-intro{
    padding-top:20px;
    padding-bottom:10px;
    @include susy-media($medium-up){
            padding-top:40px;
            padding-bottom:20px;
    }
    @include susy-media($large-up){
        padding-top:80px;
        padding-bottom:40px;
    }
    @include span(12 break);
    
    .content{
       text-align:center;
        font-size:$font-size-normal;
        @include span(isolate 10 at 2 of 12 break);
        @include susy-media($medium-up){
            @include span(isolate 6 at 4 of 12 break);
        }
    }
}
#block-bean-rekrutacja-etapy-, #block-bean-recruitment-steps-reference, #block-bean-der-rekrutierungsprozess-fazen{
    @include span(12 break);
    padding-top:0;
    padding-bottom:20px;
    @include susy-media($medium-up){
            padding-top:0;
            padding-bottom:20px;
    }
    @include susy-media($large-up){
        padding-top:0;
        padding-bottom:40px;
    }
    .field-name-field-block-reference{
        @include span (isolate 10 at 2 of 12);
        @include susy-media($large-up){
            @include span (isolate 8 at 3 of 12);
        }
        .field-item{
            margin:15px 0;
            @include span (12 break);
            @include susy-media($medium-up){
                @include span (1 of 3 nobreak);
                
                &:nth-of-type(3){
                    
                    @include span (1 of 3 last nobreak);
                     background:none;
                }
            }
            
            @include susy-media($xlarge-up){
                // background:url(../images/arrow.png) no-repeat right  center;
            }
            .field-type-image{
                text-align:center;
                
                margin:10px auto;
                display:block;
                img{
                    height:auto;
                    max-width:60%;
                    margin:10px auto;
                    display:block;
                    @include susy-media($medium-up){
                        max-width:80%;
                    }
                    
                    
                }
            }
             .field-type-text{
                text-align:center;
                @include span(10 of 12 break);
                @include pre(1);
                @include post(1);
                font-size:$font-size-small;
                @include susy-media($small-up){
                    font-size:$font-size-normal;
                }
                @include susy-media($medium-up){
                    
                    font-size:$font-size-normal;
                    line-height:$font-size-normal +4 ;
                }
            }
        }
    }
}
#block-bean-dla-kandydatow-aktualne-oferty{
    h2.title{
        display:none;
    }
}

#block-bean-dla-kandydatow-aktualne-oferty, #block-bean-offers-header, #block-bean-aktuelle-stellenangebote{
     @include span(12 break);
     background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/emp/oferty_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             @include susy-media($xlarge-up){
                   background-position: right  center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                
               .field-name-field-zawarto- h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/emp/oferty_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                    }
                    
                 
            }
        }
}




#block-bean-czas-wolny-co-lubimy-ikony, #block-bean-we-really-like, #block-bean-was-wir-mogen{
  @include susy-media($xmedium-up){
    padding:30px 0;
  }
  @include susy-media($large-up){
    padding:40px 0;
  }
  background:$color-grey-light;
   @include span (12 break);
   > .block-content-wrapper{
    @include span(10 of 12 break);
    @include pre(1);
    @include post(1);
    h2{
      text-align:center;
      color:$color-blue;
      text-transform:uppercase;
      margin-bottom:40px;
    }
    .entity-bean .field-name-field-block-reference{
      .field-items{
        .field-item{
          margin-bottom:20px;
          &:nth-of-type(1){
            background:url(../images/emp/czaswolny_gokarty.png) center center no-repeat;
          }
          &:nth-of-type(2){
            background:url(../images/emp/czaswolny_paintball.png) center center no-repeat;
          }
          &:nth-of-type(3){
            background:url(../images/emp/czaswolny_nozna.png) center center no-repeat;
            
          }
          &:nth-of-type(4){
            background:url(../images/emp/czaswolny_kregle.png) center center no-repeat;
          }
          &:nth-of-type(5){
            
            background:url(../images/emp/czaswolny_bilard.png) center center no-repeat;
          }
            background-size:auto 80%;
            
            @include susy-media($small-up){
               background-position: center 10px center ;
               background-size:auto 80%;
            }
            @include susy-media($xmedium-up){
               background-position: center center ;
               background-size:auto 70%;
            }
          
          
          
          
          
          text-align:center;
          border:3px solid #fff;
          border-radius:5px;
         
          display:flex;
          align-items:center;
          color:$color-blue;
          text-transform:uppercase;
          padding:40px 40px;
          p{
              font-size:$font-size-normal;
           }
          width:100%;
          > .entity-bean{
            width:100%;
            display:block;
          }
          @include span(12 of 12 break);
          @include susy-media($xmedium-up){
            @include gallery(6 of 12);
             padding:40px 20px 40px 20px;
            p{
              font-size:$font-size-normal;
            }
            
          }
          @include susy-media($large-up){
            @include gallery(2 of 10);
            padding:40px 40px;
             p{
              font-size:$font-size-normal;
            }
          }
        }
      }
    }
  }
}


#block-bean-dla-kandydatow-na-skroty, #block-bean-for-candidates-shortcuts{
  
    margin:30px 0;
    text-align:center;
    font-size:$font-size-small;
    
    .field-type-text{
        text-transform:uppercase;
        font-size:$font-size-smaller;
        padding:5px;
        font-weight:normal;
        
        @include susy-media($medium-up){
            font-weight:700;
            padding:20px 15px 0 15px;
            font-size:$font-size-normal;
        }
        @include susy-media($large-up){
            padding:20px 35px 0 35px;
            font-size:20px;
        }
        font-family: $font-primary;
    }
    
    @include span(10 break);
    @include pre(1);
    @include post(1);
    .field-name-field-block-reference{
        .field-items{
            .field-item{
                cursor:pointer;
                cursor:hand;
                margin-bottom:20px;
                 @include span(12 of 12 break);
                 @include susy-media($xsmall-up $large-up){
                        @include span(4 of 12 nobreak);
                        &:nth-of-type(3){
                          @include span(4 of 12 last);  
                        }
                        &:nth-of-type(4){
                            @include span(6 of 12); 
                        }
                        &:nth-of-type(5){
                            @include span(6 of 12 last); 
                        }
                    }
                @include susy-media($large-up){
                     @include gallery(2 of 10 nobreak);
                 }
            }
        }
    }
}
