#block-bean-baner-referencje{
   h2.title{
        display:none;
    }
}


.view-id-referencje.view-display-id-page{
   
    @include span(12 break);
    padding:65px 20px;
    @include susy-media($medium-up){
         padding:65px 0;
        @include span(isolate 10 at 2 of 12 break);
    }
    .views-row {
       
        display:flex;
        align-items:center;
        padding:20px;
        margin:10px 0;
        border:1px solid $color-grey-light;
        border-radius:5px;
        position:relative;
        @include span (12 of 12 break);
        
       .views-field-field-logo {
            text-align:center;
            max-width:100%;
            text-aling:center;
        }
        .views-field-field-logo img{
            max-width:80% ;
            height:auto;
            margin:0 auto;
            display:block;
           
         }
         
        @include susy-media($large-up){
            @include span (3 of 9 nobreak);
            height:300px;
            &:nth-of-type(3){
                @include span (3 of 9 last nobreak);
            }
       
        }
       
        .views-field-field-tekst-referencji{
        display:flex;
        align-items:center;
        display:none;
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        border-radius:5px;
        color:#fff;
        line-height:110%;
        font-size:$font-size-small;
         @include susy-media($large-up){
            font-size:$font-size-normal - 2; 
         }
        .field-content{
            position:relative;
            width:auto;
            height:275px;
            border-radius:5px;
            margin:10px;
            padding:10px;
            background:$color-blue;
        }
    }
  //  &:hover{
  //     .views-field-field-tekst-referencji{
  //          display:block;
  //    }
  //  }
    }
   
    
}
