$font-base: 'HelveticaNeueCondensedPL-Black', sans-serif;
$font-cond: 'HelveticaNeueCondensedPL-Bold', sans-serif;
$font-default: arial, helvetica, sans-serif;

// Font sizes
$font-size-smaller: 12px;
$font-size-small: 13px;
$font-size-normal: 14px;
$font-size-default: 16px;
$font-size-bigger: 18px;
$font-size-morethanbigger: 20px;
$font-size-medium:24px;
$font-size-xm:26px;
$font-size-xxm:28px;
$font-size-lessextra: 30px;
$font-size-midextra: 40px;
$font-size-extra: 46px;
$font-size-extraxl: 56px;
$font-primary: 'exo1';
$light:300;
$normal:400;
$bold:700;
// Colors
$color-blue : #009fe3;
$color-blue-light : #75ccf1;
$color-grey-light : #f4f4f4;
$placeholer-color : #ccc;

// Screen containers
$container-small: 600px;
$container-medium: 10000px;
$container-large: 1200px;
$container-xlarge: 1300px;
$container-xxlarge: 1600px;
$container-xxxlarge: 1681px;

// Paddings 
$padding-default: 14px;
$padding-big: 20px;
$padding-bigger: 28px;
$padding-extra: 50px;

// Transitions
$transition-default: all .3s ease;
// Maps
$map-nogutters: (gutters: 0);

// Heights
$height-cta-full: 663px;
$height-cta-medium: 331px;
$height-cta-large: 500px;
$height-cta-xlarge: 596px;
$height-cta-xxlarge: 535px;

// Line heights 
$line-height-normal: 21px;
$line-height-h1: $line-height-normal+3px;
$line-height-h2: $line-height-normal - 3px;
$line-height-h3: $line-height-normal - 3px;
$line-height-extra: $line-height-normal + 25px;


// Margins
$margin-default: 5%;

$path-images: '../img/';
