#block-bean-baner-kontakt{
   @include span(12 break);
    h2.title{
        display:none;
    }
    img{
        width:100%;
        height:auto;
    }
}
#block-entityform-block-kontakt{
    @include span(12 break);
}

.entitytype-kontakt-form{
    background:$color-grey-light;
    @include span(12 break);
    input{
       box-sizing: border-box;
        width:100%;
        font-size:$font-size-default;
        padding:13px 20px;
        line-height:18px;
        border:1px solid #ccc;
        border:none;
        border-radius:5px;
        color:#666;
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    $placeholer-color;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color:    $placeholer-color;
           opacity:  1;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
           color:    $placeholer-color;
           opacity:  1;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
           color:    $placeholer-color;
        }
    }
    label{
        color:#ccc;
    }
    
    .form-required{
        color:#000;
       
    }
    textarea{
        box-sizing: border-box;
        width:100%;
        border:none;
        color:#666;
        height: 170px; 
        line-height : 18px;
        border-radius : 5px;
        font-size : $font-size-default;
        padding : 15px 20px;
        margin : 0 auto;
        resize : no;
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    $placeholer-color;
            content: '*';
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color:    $placeholer-color;
           content: '*';
           opacity:  1;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
           color:    $placeholer-color;
           content: '*';
           opacity:  1;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
           color:    $placeholer-color;
           content: '*';
        }
    }
    .grippie{
        display:none;
    }
    .group-left-gr{
        text-align:center;
        input{
           max-width:100%;
        }
        @include pre(1);
        @include post(1);
        @include span(10 of 12 break);
        @include susy-media($xmedium-up){
            @include pre(2);
            @include post(0);
            @include span(3 of 12 nobreak)
        }
    }
    .entityform.entitytype-kontakt-form{
        @include container;
    }
    .group-right-gr{
        text-align:center;
        input{
            max-width:100%;
        }
        @include pre(1);
        @include post(1);
        @include span( 10 at 2 of 12 break);
        @include susy-media($xmedium-up){
            @include pre(0);
            @include post(2);
            @include span(5 of 12 nobreak)
        }
    }
    .form-actions{
        text-align:center;
        @include span(12 break);
        input[type=submit]{
            width:150px;
            background:$color-blue;
            border:none;
            border-radius:5px;
            padding:7px 20px;
            color:#fff;
        }
    }
    
}
#gmap{
    @include  span (12 break);
    height:400px;
}
#block-bean-kontakt-adres-siedziby, #block-bean-postal-address-ss-, #block-bean-postanschrift{
    @include span (12 break);
    text-align:center;
}
#block-bean-dane-rejestrowe, #block-bean-postal-address, #block-bean-impressum{
    text-align:center;
    @include span (12 break);
}