#block-system-main-menu{
    text-align:right;
    ul{
        float:right;
        margin:0;
        padding:0;
        list-style:none;
        li.leaf{
            list-style:none;
        }
        li{
            list-style:none;
            float:left;
            padding:0 10px;
            a{
                font-size:$font-size-normal;
                font-family:$font-primary;
                text-decoration: none;
                font-weight:700;
                text-transform:uppercase;
                color:#000;
            }
            &.first{
                padding-left:0;
            }
            &.last{
                padding-right:0;
            }
        }
        &:last-of-type{
            &:after{
                content:'';
                clear:both;
            }
        }
            
    }
}
.responsive-menus.responsified {
    text-align:right;
    span.toggler{ 
        display:inline-block;
        text-align:right;
        background:none;
        padding:5px;
        border:1px solid $color-grey-light;
        border-radius:5px;
        box-shadow:none;
        color:#000;
    }
    .responsive-menus-simple{
        background:#fff;
        color:#000;
        box-shadow:none;
        text-align:center;
        border:1px solid $color-grey-light;
    }
}
