#block-bean-baner-o-nas, #block-bean-about-us-0{
   h2.title{
        display:none;
    }
}


.bean-banner{
   position:relative;
   @include span(12 break);
    h2.title{
        display:none;
    }
    img{
        width:100%;
        height:auto;
        display:block;
    }
    .field-type-text
    {   text-align:center;
        position:absolute;
        top:40%;
        padding:0 10px;
        @include span(12 of 12);
        font-size:$font-size-extra - 20;
        @include susy-media($medium-up){
            padding:0;
            font-size:$font-size-extra - 6;
            @include span(isolate 10 at 2 of 12);
        }
        @include susy-media($xmedium-up){
            font-size:$font-size-extra+10;
        };
        
        text-transform:uppercase;
        color:#fff;
        }
}

#block-bean-page-o-nas-wstep, #block-bean-about-us, #block-bean-uber-uns-intro-de{
    @include span(12 break);
    margin:-1px 0;
    ul{
        margin:0;
        padding:0;
        li{
            background: url(../images/biz/outsourcingit_icon_wypunktowanie.png) no-repeat left top;
            padding: 5px 0px 5px 35px;
            /* reset styles (optional): */
            list-style: none;
            margin: 0;
            
        }
    }
    .block-content-wrapper{
              
                @include susy-media($large-up){
                
                background-image:url(../images/o_nas/opis_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right top;
        }
        .block-content{
                
                 background-color:#fff;
                 width:100%;
                 display: flex;
                 align-items: center; 
                 @include susy-media($large-up){
                     min-height:588px;
                     width:50%;
                     background-position: left center;
                     background-size:cover;
                  }
                   @include susy-media($xlarge-up){
                   
                    }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: left center;
                   
                 }
                 
        }
        .content{
             padding:10px 0;
             text-align:justify;
             font-size:$font-size-small;
             
             @include susy-media($xsmall-up){
                padding:20px 0;
                font-size:$font-size-normal;
             }
             
             @include susy-media($medium-up){
                 text-align:left;
                 font-size:$font-size-default;
             }
             @include susy-media($large-up){
                padding:40px 0;
             }
             
             font-size:$font-size-default;
             font-family:$font-primary;
        }
        .entity-bean{
                    color:#000;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 4 at 2 of 6 break);
                    }
                }
  }
}
#block-bean-page-o-nas-wartosci, #block-bean-unsere-eigenschaften{
   
    @include span(12 break);
    h2.title{
       font-size:$font-size-lessextra;
       color:#fff;
       font-weight:300;
       margin:0;
       background-color:$color-blue;
       text-align:center;
       @include span(12 break);
       padding:40px 0;
    }
    .field-type-entityreference{
        @include span(12 break);
        .field-item{
            display: flex;
              /* flex-direction: column;*/
            align-items: center; 
            
            padding:15px;
            line-height:130%;
            font-size:$font-size-small;
            text-align:left;
            @include span(12 break);
            background-color:#fff;
            &:nth-of-type(2n){
                @include span(12 break);
                 background-color:$color-grey-light;
            }
            @include susy-media($small-up){
                font-size:$font-size-normal;
               
                padding:20px;
            }
            @include susy-media($medium-up){
                font-size:$font-size-default;
                
                padding:30px;
                line-height:150%;
            }
            @include susy-media($large-up){
                line-height:160%;
                 padding:50px;
                 &:nth-of-type(2n){
                      background-color:#fff;
                 }
                &:nth-of-type(1),&:nth-of-type(4),&:nth-of-type(5), &:nth-of-type(8) {
                    background-color:$color-grey-light;
                }
            }
            @include susy-media($large-up){
                .field-type-text{
                    line-height:180%;
                }
                @include span(6 of 12 nobreak border-box);
                &:nth-of-type(2n){
                    @include span(6 of 12 nobreak border-box );
                   
                }
                &.even{
                    width:50%;
                    margin-right:0;
                    clear:left;
                }
                 &.odd{
                    width:50%;
                    margin-right:0;
                    float:left;
                }
            }
            .entity-bean{
               .content{
                    display: flex;
                    /* flex-direction: column;*/
                    align-items: center;
               } 
               
               @include span(12 of 12 nested);
               .field-type-image{
                   
                   @include span(2 of 5);
                   img{
                        display:block;
                        margin:0 auto;
                        height:5em;
                        width:auto;
                   }
               } 
               .field-type-text{
                   @include span(3 of 5 last);
               }
            }
        }
        
    }
}
