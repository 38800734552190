footer{
    margin-bottom:-10px;
    @include container;
    background-color:$color-blue;
    padding:45px 0 10px 0;
    color:#fff;
    p{
      margin:0;
    }
    #block-bean-dane-kontaktowe-stopka{
        padding:1px;
        
        @include pre(1);
        @include span(10 of 12 break);
         
        @include susy-media($xsmall-up){
           @include pre(2);
           @include span(9 of 12 break);
        }  
        @include susy-media($small-up){
           @include pre(4);
           @include span(8 of 12 break);
        }
        @include susy-media($medium-up){
           @include pre(4);
           @include span(5 of 12 nobreak);
        }
        @include susy-media($xmedium-up){
          @include pre(2);
           @include span(4 of 12 nobreak);
        }
        @include susy-media($large-up){
           
           @include pre(2);
           @include span(4 of 12 nobreak);
        }
        @include susy-media($xlarge-up){
           @include pre(2);
           @include span(4 of 12 nobreak);
        }
        .block-content{
            display:flex;
            align-items:center;
            //padding-left:50px;
            
            font-size:$font-size-smaller;
            font-family:$font-primary;
            font-weight:light;
            line-height:25px;
             
            background: url(../images/icon_footer_adres.png) no-repeat left center;
            background-size:auto 40px;
            min-height:40px;
            @include susy-media($xsmall-up){
                font-size:$font-size-default - 3;
                //padding-left:55px;
               
            }
            
            @include susy-media($small-up){
                font-size:$font-size-default -2;
                //padding-left:70px;
                background-size:auto 50px;
                min-height:50px;
            }
            
            @include susy-media($medium-up){
            
                p{
                  font-size:$font-size-normal;
                }
                //padding-left:85px;
                background-size:auto 65px;
                min-height:65px;
            }
            @include susy-media($xmedium-up){
                font-size:$font-size-default;
            }
            @include susy-media($large-up){}
            @include susy-media($xlarge-up){}
          
            .entity-bean{
                padding-left:55px;
                    @include susy-media($xsmall-up){
                       
                        padding-left:55px;
                    }
                    
                    @include susy-media($small-up){
                        padding-left:70px;
                    }
                    
                    @include susy-media($medium-up){
                         padding-left:85px;
                    }
                    
            }
        }
    }
    #block-bean-mail-stopka{
        margin-top:20px;
        padding:1px;
        @include pre(1);
        @include span(10 of 12 break);
        @include susy-media($xsmall-up){
           @include pre(2);
           @include span(9 of 12 break);
        }  
        @include susy-media($small-up){
           @include pre(4);
           @include span(8 of 12 break);
        }
        @include susy-media($medium-up){
           margin-top:0;
           @include pre(4);
           @include span(5 of 12 nobreak);
        }
        @include susy-media($xmedium-up){
           @include pre(0);
           @include post(2);
           @include span(4 of 12 nobreak);
        }
        @include susy-media($large-up){
          @include pre(0);
           @include post(2);
           @include span(4 of 12 nobreak);
        }
        @include susy-media($xlarge-up){
            @include pre(0);
            @include post(2);
            text-align:left;
           @include span(4 of 12 nobreak);
        }
        .block-content{
          
          // padding-left:55px;
          display:flex;
          align-items:center;
          background: url(../images/icon_footer_email.png) no-repeat left center;
          background-size:auto 40px;
          min-height:40px;
          font-size:$font-size-small;
          font-weight:normal;
          font-family:$font-primary;
          font-weight:700;
          min-height:40px;
       
          margin-top:0;
          p{
            margin:0;
          }
            a{
              color:#fff; 
              text-decoration:none;
              line-height:200%;
            }
            @include susy-media($xsmall-up){
                font-size:$font-size-normal;
            }
            
            @include susy-media($small-up){
                font-size:$font-size-default;
              //  padding-left:70px;
                background-size:auto 50px;
               min-height:50px;
                min-height:50px;
            }
            
            @include susy-media($medium-up){
                font-size:$font-size-default;
              //  padding-left:85px;
                font-weight:bold;
                background-size:auto 65px;
                min-height:65px;
            }
            @include susy-media($xmedium-up){
            }
            @include susy-media($large-up){
                font-size:$font-size-bigger;
                margin-top:0px;
            }
            .entity-bean{
                padding-left:55px;
                    @include susy-media($xsmall-up){
                       
                        padding-left:55px;
                    }
                    
                    @include susy-media($small-up){
                        padding-left:70px;
                    }
                    
                    @include susy-media($medium-up){
                         padding-left:85px;
                    }
                    
            }
        }
    }
    #block-bean-copyrights{
       
         font-size:$font-size-smaller;
         @include susy-media($medium-up){
            font-size:$font-size-normal;
         }
         @include susy-media($xmedium-up){
            font-size:$font-size-default;
         }
         margin:20px;
         margin-top:40px;
         @include pre(1);
         @include post(1);
         @include span(10 of 12 break);
         text-align:center;
    }
}

