#sliding-popup{
  .popup-content{
    #popup-buttons{
      button{
        border:1px solid #fff;
        border-radius:5px;
        color:#fff;
        font-size:16px;
        background:none;
        text-shadow:none;
        box-shadow:none;
        padding:10px 15px;
        &:hover{
          background:#fff;
          color:$color-blue;
        }
      }
    }
  }
}
