body.front{
    #block-views-oferty-block{
     
       background-size: auto auto;
       background-repeat:no-repeat;
       background-position:left;

     
        .block-content-wrapper{
        
            @include span(12 break);
            .block-content{
                @include clear;
                .views-row-even{
                    background-color: #f9f9f9;
                }
              
            }
        }
    }
    #block-block-3{
        
        @include span(12 break);
        h2{
            @include span(12 break);
            @include block-header;
            text-align:center;
        }
       
    }
}
 