#views_slideshow_cycle_main_front_page_slider1-block{
    padding-bottom:37%;
    .views-slideshow-cycle-main-frame, .views-slideshow-cycle-main-frame-row{
        width:100% !important;
        height:auto !important;
    }
    .views_slideshow_cycle_main:after{
        content:'';
        clear:both;
        display:block;
    }
    @include span(12);
    .views-field-field-grafika{
        img{
            width:100% !important;
            height:auto !important;
        }
    }
   
    .views-field-field-tekst{
        
        p{
            display:none;
            line-height:140%;
            
            @include susy-media($medium-up){
                display:block;
            }
            @include susy-media($xmedium-up){
                line-height:150%;
            }
        }
    
        h2{
            text-align:center;
            font-size:$font-size-normal;
            margin:5px 0;
            @include susy-media($medium-up){
                text-align:left;
                font-size:$font-size-morethanbigger;
            } 
            @include susy-media($xmedium-up){
                font-size:$font-size-lessextra - 4;
            } 
            @include susy-media($large-up){
                font-size:$font-size-lessextra - 2;
            } 
            @include susy-media($xlarge-up){
                font-size:$font-size-extra - 8;
            } 
            @include susy-media($xxlarge-up){
                font-size:$font-size-extra;
            }
        }
        font-size:$font-size-small;
        @include span(isolate 10 at 2 of 12);
        padding:10px;
        position:absolute;
        bottom:20px;
        color:#fff;
        font-family:$font-primary;
        background:rgba(0, 159, 227, 0.8 );
        border-radius:10px;
        @include susy-media($medium-up){
           
        }
        @include susy-media($xmedium-up){
            font-size:$font-size-default;
            padding:20px 30px;
            bottom:50px;
            @include span(isolate 6 at 6 of 12);
        }
         @include susy-media($xlarge-up){
             bottom:100px;
         }
        
    }
    &:after{
        content:'';
        clear:both;
        display:block;
    }
}
 