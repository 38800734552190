#block-bean-baner-oferta-dla-biznesu, #block-bean-for-business{
    @include span(12 break);
    h2.title{
        display:none;
    }
}
#block-bean-dla-biznesu-szpalta-1, #block-bean-for-bussines-first-column, #block-bean-angebot-fuer-unternehmen-intro{
    @include span(isolate 10 at 2 of 12 break);
    padding-top:15px;
    @include susy-media($xmedium-up){
        padding:20px 0;
    }
    @include susy-media($large-up){
        @include span(isolate 5 at 2 of 12 break);
    }
}

#block-bean-dla-biznesu-szpalta-2, #block-bean-for-bussines-second-column, #block-bean-angebot-fuer-unternehmen-int-0{
    @include span(isolate 10 at 2 of 12 break);
         @include susy-media($xmedium-up){
            padding:20px 0;
          }
          @include susy-media($large-up){
            @include span(isolate 5 at 7 of 12 nobreak);
          }
}
#block-bean-oferta-dla-biznesu-ikony, #block-bean-for-bussines-icons{
    margin:15px 0;
    @include span(12 of 12 break);
    .field-item{
        cursor:pointer;
        cursor:hand;
        text-align:center;
        @include span(4 of 12);
        &:nth-of-type(3){
                @include span(4 of 12 last);
        }
        &:nth-of-type(4){
            padding-top:20px;
            @include span(6 of 12);
        }
        &:nth-of-type(5){
             padding-top:20px;
            @include span(6 of 12 last);
        }
        img{height:5em; width:auto;}
        font-size:$font-size-small;
        @include susy-media($large-up){
            margin:35px 0;
            &:nth-of-type(3){
                padding-top:0;
                @include span(2 of 10);
            }
             &:nth-of-type(4){
                padding-top:0;
                @include span(2 of 10);
            }
            @include span(2 of 10);
              &:nth-of-type(5){
                  padding-top:0;
                  @include span(2 of 10 last);
              }
        }
    }
    
    
    .field-type-image{
        height:100px;
        text-align:center;
        img {
            max-width:70%;
            height:auto;
            vertical-align: middle;
            @include susy-media($medium-up){
                max-width:80%;
                height:auto;
                vertical-align: middle;
            }
            
            
        }
    }
    
    .field-type-image:before,
    .frame_before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    .field-type-image {
        list-style:none;
        behavior: expression(
            function(t){
                t.insertAdjacentHTML('afterBegin','<span class="frame_before"></span>');
                t.runtimeStyle.behavior = 'none';
            }(this)
        );
    }
    .bean-referencing-block{
        padding-bottom:20px;
        @include susy-media($large-up){
            padding-bottom:80px;
        }
    }
    .field-type-text{
        text-transform:uppercase;
        font-size:$font-size-smaller;
        padding:5px;
        font-weight:normal;
        
        @include susy-media($medium-up){
            font-weight:700;
            padding:20px 15px 0 15px;
            font-size:$font-size-normal;
        }
        @include susy-media($large-up){
            padding:20px 35px 0 35px;
            font-size:20px;
        }
        font-family: $font-primary;
    }
    
}

#block-bean-outsourcing-hr-naglowek, #block-bean-for-business-hr-outsourcing-head, #block-bean-outsourcing-der-hr-prozessen-hea{
        @include span(12 break);
        background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/biz/outsourcinghr_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                 @include susy-media($medium-up){
                    height:200px;
                 }
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                 h2{
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/biz/outsourcinghr_icon.png) no-repeat left center;
                        background-size:65px auto;
                        padding:20px 0; 
                        padding-left:75px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        margin:0 auto;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                            margin:0 auto;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            margin:0 auto;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                 }
            }
        }
}
#block-bean-outsourcing-hr-tekst, #block-bean-for-business-hr-outsourcing-co,#block-bean-outsourcing-der-hr-prozessen-con{
        background-image:url(../images/biz/outsourcinghr_bg_opis.jpg);
        @include span(12 break);
        .block-content{
            padding:15px 0;
            @include span(isolate 10 at 2 of 12 break);
            @include susy-media($medium-up){
                  @include span(isolate 6 at 4 of 12 break);
                  padding:30px 0;
            }
            @include susy-media($large-up){
                padding:65px 0;
            }
            text-align:center;
            color:$color-blue-light;
            font-size:17px;
            font-family:$font-primary;
        }
}


#block-bean-outsourcing-it-naglowek, #block-bean-for-business-it-outsourcing-he, #block-bean-it-outsourcing{
     @include span(12 break);
     background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/biz/outsourcingit_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                .entity-bean{
                    .content{
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                 h2{
                     margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/biz/outsourcingit_icon.png) no-repeat left center;    
                        background-size:65px auto;
                        padding:20px 0; 
                        padding-left:75px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        @include susy-media($small-up){
                            margin:15px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                            margin:15px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            margin:15px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                 }
            }
        }
}
#block-bean-outsourcing-it-tekst, #block-bean-for-business-it-outsourcing-co, #block-bean-it-outsourcing-content{
        background-image:url(../images/biz/outsourcinghr_bg_opis.jpg);
        @include span(12 break);
        .block-content{
            @include span(isolate 10 at 2 of 12 break);
            @include susy-media($medium-up){
                  @include span(isolate 6 at 4 of 12 break);
            }
            text-align:center;
            color:$color-blue-light;
            
             padding:15px 0;
            @include span(isolate 10 at 2 of 12 break);
            @include susy-media($medium-up){
                  @include span(isolate 6 at 4 of 12 break);
                  padding:30px 0;
            }
            @include susy-media($large-up){
                padding:65px 0;
            }
            font-size:17px;
            font-family:$font-primary;
        }
}
#block-bean-outsourcing-it-zalety, #block-bean-for-business-it-outsourcing-ad, #block-bean-die-vorteile-von-it-outsourcing{
    @include span(12 break);
    margin: 0;

    display:flex;
    align-items:center;
    
    ul{
        margin:0;
        padding:0;
        li{
            background: url(../images/biz/outsourcingit_icon_wypunktowanie.png) no-repeat left top;
            padding: 5px 0px 10px 55px;
            /* reset styles (optional): */
            list-style: none;
            margin: 0;
            line-height:140%;
            font-size:$font-size-small;
            @include susy-media($medium-up){
                font-size:$font-size-normal+1;
            }
            @include susy-media($xxlarge-up){
                line-height:180%;
                font-size:$font-size-default;
            }
            
            
        }
    }
    .block-content-wrapper{
            @include span(12 break);
            @include susy-media($large-up){
                background-image:url(../images/biz/outsourcingit_foto_2.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: left center;
        }
        .block-content{
                 padding:40px 0;
                 display:flex;
                 align-items:center;
                 background-color:#fff;
                 width:100%;
               
                  @include susy-media($large-up){
                     min-height:498px;
                     padding:0;
                     width:50%;
                     margin-left:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                   
                 }
                 
        }
        .content{
             display:flex;
             align-items:center;
           
             font-size:$font-size-default;
             font-family:$font-primary;
        }
        .entity-bean{
                    display:flex;
                    align-items:center;
                    color:#000;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 4 at 2 of 6 break);
                    }
                    
                }
  }
}
#block-bean-wdrozenie-outrsourcingu-5-krokow, #block-bean-for-business-it-5-steps, #block-bean-der-outsourcing-umsetzungsprozes{
    h2{
        font-size:$font-size-default;
        @include susy-media($medium-up){
            font-size:$font-size-bigger;
        }
        font-family:$font-primary;
        font-weight: 400;
        padding-bottom:20px;
    }
    @include span(12 break);
    background:#f4f4f4;
    padding:25px 0 25px 0;
    @include susy-media($medium-up){
        padding:65px 0 100px 0;
    }
    
    .block-content-wrapper{
    @include span(isolate 10 at 2 of 12 break);
    .field-item{
        .arrow{
          margin-top:20px;
          width:100%;
          height:auto;
          padding-top:20%;
          background:url(../images/biz/icon_intro_link.png) no-repeat top center;
          background-size:contain;
        }
        
        &:hover .arrow{
          
          -webkit-animation:bounce 1s infinite;
          animation: bounce 1s infinite;
        }
        
        @-webkit-keyframes bounce {
          0%, 100% {
            -webkit-transform: translateY(0); }
          40%, 60% {
            -webkit-transform: translateY(25px); }
          50% {
            -webkit-transform: translateY(30px); }
          20%, 80% {
            -webkit-transform: translateY(15px); }  }
            
        @-moz-keyframes bounce {
          0%, 100% {
            -moz-transform: translateY(0); }
          20% {
            -moz-transform: translateY(15px); }
          40% {
            -moz-transform: translateY(25px); }
          50% {
            -moz-transform: translateY(30px); }
          60% {
            -moz-transform: translateY(25px); } 
          80% {
            -moz-transform: translateY(15px); } }  
        @keyframes bounce {
          0%,  100% {
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0); }
          20%, 80%{
                -webkit-transform: translateY(15px);
            -moz-transform: translateY(15px);
            -ms-transform: translateY(15px);
            -o-transform: translateY(15px);
            transform: translateY(15px); }
          40%, 60% {
            -webkit-transform: translateY(25px);
            -moz-transform: translateY(25px);
            -ms-transform: translateY(25px);
            -o-transform: translateY(25px);
            transform: translateY(30px); }
          50%{
            -webkit-transform: translateY(30px);
            -moz-transform: translateY(30px);
            -ms-transform: translateY(30px);
            -o-transform: translateY(30px);
            transform: translateY(30px); } 
         }
        text-align:center;
        background-color:#fff;
        border-radius:5px;
        
        @include span(12 of 12);
        background-repeat:no-repeat;
        background-position:left 20px center;
        min-height:140px;
        margin-bottom:20px;
        background-size:100px auto;
        
        
        display:flex;
        align-items:center;
        @include susy-media($large-up){
            display:block;
        }
        display:flex;
        align-items:center;
            &:nth-of-type(1){
                background-image:url(../images/biz/outsourcingit_icon_wdrozenie1_szary.png);
            }
            &:nth-of-type(2){
                background-image:url(../images/biz/outsourcingit_icon_wdrozenie2_szary.png);
            }
            &:nth-of-type(3){
                background-image:url(../images/biz/outsourcingit_icon_wdrozenie3_szary.png);
            }
            &:nth-of-type(4){
                background-image:url(../images/biz/outsourcingit_icon_wdrozenie4_szary.png);
            }
            &:nth-of-type(5){
                  background-image:url(../images/biz/outsourcingit_icon_wdrozenie5_szary.png);
            }
        background-position:center center;
        font-size:$font-size-normal;
        @include susy-media($small-up){
            padding-left:120px;
           
            background-position:left 20px center;
        }
        @include susy-media($medium-up){
            font-size:$font-size-default;
        }
        @include susy-media($large-up){
            background-image:none;
            padding-left:0;
            font-size:$font-size-normal;
            line-height:150%;
            @include span(2 of 10);
            
            background-size:80% auto;
            background-position:center center;
            &:nth-of-type(1){
                background-image:none;
            }
            &:nth-of-type(2){
                 background-image:none;
            }
            &:nth-of-type(3){
                 background-image:none;
            }
            &:nth-of-type(4){
                 background-image:none;
            }
            &:nth-of-type(5){
                  padding-top:0;
                  @include span(2 of 10 last);
                  background-image:none;
            }
        }
         @include susy-media(1200px){
            
         }
         @include susy-media($xxlarge-up){
              border-radius:10px;
                font-size:$font-size-default;
                line-height:150%;
         }
    
        .entity-bean{
            width:100%;
            
            display:block;
            padding:20px 10px;
            .field-type-image{
                padding:10px 0;
                margin-bottom:20px;
                 display:none;
                @include susy-media($medium-up){
                    padding:10px 0;
                    img{
                        width:90px;
                        height:auto;
                        
                    }
                } 
                @include susy-media($large-up){
                   display:block; 
                }
                
                @include susy-media($xxlarge-up){
                    padding:20px 0;
                    img{
                        width:auto;
                        height:auto;
                    }
                }
            }
        }
        .field-type-text{
            padding:0 5px 10px 5px;
            line-height:120%;
            @include susy-media($large-up){
                line-height:140%;    
            }
            @include susy-media($xxlarge-up){
                line-height:180%;
                padding:0 20px 20px 20px;
            }
        }
        
    }

    
    }
}
#block-bean-post-steps-content-i, #block-bean-for-business-it-out-first-colu, #block-bean-umsetzungsprozess-postcontent-{
    font-family:$font-primary;
    font-size:$font-default;
    line-height:25px;
    text-align:justify;
    @include susy-media($small-up){
        text-align:left;
    }
    @include span(isolate 10 at 2 of 12 break);
    @include susy-media($large-up){
        @include span(isolate 5 at 2 of 12 break);
    }
    .content{
       padding:10px;
       @include susy-media($large-up){
            padding:20px;
        }
    }
}
    

#block-bean-post-steps-content-ii, #block-bean-for-business-it-out-second-col, #block-bean-umsetzungsprozess-postconten-0{
    font-family:$font-primary;
    font-size:$font-default;
    line-height:25px;
    text-align:justify;
    @include susy-media($small-up){
        text-align:left;
    }
    @include span(isolate 10 at 2 of 12 break);
    @include susy-media($large-up){
        @include span(isolate 5 at 7 of 12 nobreak);
    }
    .content{
       padding:10px;
       @include susy-media($large-up){
            padding:20px;
        }
    }
}




#block-bean-wdrozenia-rozwiazan-it-naglowe, #block-bean-for-business-it-soultions-heda, #block-bean-einfuehrung-der-it-loesungen{
     @include span(12 break);
     background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/biz/wdrozenia_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right center;
            }
             width:100%;
             .block-content{
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                  .entity-bean{
                    .content{
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/biz/wdrozenia_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                 }
            }
        }
}

#block-bean-wdrozenia-rozwiazan-it-tekst, #block-bean-for-business-it-solutions-text, #block-bean-einfuehrung-der-it-content{
        background-image:url(../images/biz/outsourcinghr_bg_opis.jpg);
        @include span(12 break);
        .block-content{
            @include span(isolate 10 at 2 of 12 break);
            @include susy-media($medium-up){
                  @include span(isolate 6 at 4 of 12 break);
            }
            text-align:center;
            color:$color-blue-light;
            padding:15px 0;
           
            @include susy-media($medium-up){
                padding:35px 0;
                
            }
            @include susy-media($large-up){
                padding:85px 0;
            }
            font-family:$font-primary;
            line-height:180%;
             
                  
        }
}
#block-bean-fazy-wdrozenia, #block-bean-implementation-stages, #block-bean-projekte-dieser-art-umfassen-fol {
    padding:15px 0;
    font-size:$font-size-normal;
    @include susy-media($medium-up){
        padding:35px 0;
        font-size:$font-size-default;
    }
    @include susy-media($large-up){
        padding:85px 0;
    }
    h2{
        font-size:$font-size-default;
        @include susy-media($large-up){
            font-size:$font-size-bigger;
        }
        font-weight:400;
        font-family:$font-primary;
        padding-bottom:15px;
        @include susy-media($medium-up){
            padding-bottom:40px;
        }
    }
    @include span(12 break);
    .block-content-wrapper{
          @include span(isolate 10 at 2 of 12 break);
          .bean-referencing-block{
              .field-item{
                  .field-name-field-ikona{
                      img{
                          display:block;
                          margin:0 auto;
                      }
                      margin-bottom:10px;
                  }
                  text-align:center;
                  @include span(5 of 10);
                  margin-bottom:40px;
                     &:nth-of-type(2n){
                           @include span(5 of 10 last);
                     }
                  @include susy-media($medium-up){
                     @include span(2 of 10);
                     margin-bottom:20px;
                     &:nth-of-type(2n){
                         @include span(2 of 10);
                     }
                     &:nth-of-type(5n){
                           @include span(2 of 10 last);
                     }
                  }
                  @include susy-media($xlarge-up){
                     @include span(1 of 10);
                      margin-bottom:0;
                      &:nth-of-type(2n){
                            @include span(1 of 10);
                     }
                      &:nth-of-type(5n){
                            @include span(1 of 10);
                      }
                     &:nth-of-type(10n){
                           @include span(1 of 10 last);
                     }
                   }
              }
              
          }
    }
}
#block-bean-wdrozenia-rozwiazan-it-summary, #block-bean-implementation-post-first-column, #block-bean-umsetzung-summary-1{
    font-family:$font-primary;
    font-size:$font-default;
    line-height:25px;
    @include span(isolate 10 at 2 of 12 break);
    @include susy-media($large-up){
        @include span(isolate 5 at 2 of 12 break);
        .content{
            padding:20px;
        }
    }
    .content{
        padding:20px;
    }
    
}
#block-bean-wdrozenia-rozwiazan-it-summa-0, #block-bean-implementation-post-second-colum, #block-bean-umsetzung-summary-2{
    font-family:$font-primary;
    font-size:$font-default;
    line-height:25px;
    @include span(isolate 10 at 2 of 12 break);
    @include susy-media($large-up){
        @include span(isolate 5 at 7 of 12 nobreak);
        .content{
            padding:20px;
        }
    }
    .content{
        padding:20px;
    }
}
#block-bean-dla-biznesu-consulting-biznesowy, #block-bean-business-consulting-header, #block-bean-unternehmensberatung-0{
    @include span(12 break);
    background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/biz/consulting_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right -200px center;
            }
             @include susy-media($xlarge-up){
                   background-position: right -150px center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 margin:0;
                 background-color:$color-blue;
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                  .entity-bean{
                    .content{
                        float:left;
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                    margin:15px 0;
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/biz/consulting_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
               
                 }
            }
        }
}
#block-bean-dla-biznesu-consulting-bizneso-0, #block-bean-business-consulting-content, #block-bean-unternehmensberatung-content-1{
        background-image:url(../images/biz/outsourcinghr_bg_opis.jpg);
        @include span(12 break);
        .block-content{
            padding:15px 0;
            @include span(isolate 10 at 2 of 12 break);
            @include susy-media($medium-up){
                 padding:35px 0;
                  @include span(isolate 6 at 4 of 12 break);
            }
            @include susy-media($large-up){
                padding:85px 0;
            }
            text-align:center;
            color:$color-blue-light;
           
            font-size:17px;
            font-family:$font-primary;
            line-height:180%;
             
                  
        }
}
#block-bean-dla-biznesu-consulting-tekst-2, #block-bean-business-consulting-content-ii, #block-bean-unternehmensberatung-content-2{
    @include span(12 break);
    margin:-1px 0;
   
    ul{
        margin:0;
        padding:0;
        li{
            background: url(../images/biz/outsourcingit_icon_wypunktowanie.png) no-repeat left top;
            padding: 5px 0px 5px 35px;
            /* reset styles (optional): */
            list-style: none;
            margin: 0;
            
        }
    }
    .block-content-wrapper{
      
            @include susy-media($large-up){
                background-image:url(../images/biz/consulting_foto_2.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: left center;
        }
        .block-content{
                 display:flex;
                 align-items:center;
                 background-color:#fff;
                 width:100%;
                 padding-bottom:15px;
                 @include susy-media($large-up){
                    min-height:475px;
                 }
                  @include susy-media($large-up){
                     width:50%;
                     margin-left:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                   
                 }
                 
        }
        .content{
             align-items:center;
             padding-top:30px;
             font-size:$font-size-default;
             font-family:$font-primary;
        }
        .entity-bean{
                    color:#000;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 4 at 2 of 6 break);
                    }
                    
                }
  }
}
#block-bean-dla-biznesu-modele-naglowek, #block-bean-co-op-models-header, #block-bean-kooperationsmodelle-header{
    @include span(12 break);
    background-color:$color-blue;
        .block-content-wrapper{
            @include susy-media($large-up){
                background-image:url(../images/biz/wspopraca_foto.jpg);
                background-size: auto auto;
                background-repeat:no-repeat; 
                background-position: right -200px center;
            }
             @include susy-media($xlarge-up){
                   background-position: right -150px center;
             }
              @include susy-media($xxlarge-up){
                  background-position: right center;
              }
             width:100%;
             .block-content{
                 background-color:$color-blue;
                 width:100%;
                  @include susy-media($large-up){
                     width:50%;
                     background-position: right center;
                  }
                 @include susy-media($xxlarge-up){
                    width:50%;
                    background-position: right center;
                    height:300px;
                 }
                 .entity-bean{
                    .content{
                        margin:0 auto;
                    }
                    color:#000;
                    display:flex;
                    height:100%;
                    align-items:center;
                    font-family:$font-primary;
                    font-weight:$light; 
                    font-size:$font-size-default;
                    @include span(isolate 10 at 2 of 12 break);
                    @include susy-media($large-up){
                         @include span(isolate 10 at 2 of 12 break);
                    }
                }
                h2{
                     vertical-align:middle;
                     
                        &:after{
                            clear:both;
                            content:'';
                            line-height:0;
                            display:block;
                        }
                        background:url(../images/biz/consulting_icon.png) no-repeat left top;
                        background-size:50px auto;
                        padding:20px 0; 
                        padding-left:60px;
                        font-size:$font-size-bigger;
                        font-family:$font-primary;
                        color:#fff;
                        font-weight:$light;
                        text-align:left;
                        float:left;
                        display:block;
                        margin:20px 0;
                        @include susy-media($small-up){
                            margin:20px 0;
                            background-size:65px auto;
                            padding:20px 0; 
                            padding-left:75px;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                         @include susy-media($medium-up){
                            background-size:auto auto;
                            display:block;
                            white-space:pre;
                            float:left;
                             margin:20px 0;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
                        
                        @include susy-media($xmedium-up){
                            background-size:auto auto;
                            display:block;
                            float:left;
                            padding:20px 0;
                            padding-left:150px;
                            background-size:contain; 
                            font-size:$font-size-extra;
                            line-height:$font-size-extra;
                        }
                        
                        @include susy-media($large-up){
                            padding:20px 0;
                            padding-left:140px;
                            text-align:left;
                            font-size:$font-size-lessextra;
                            line-height:$font-size-lessextra;
                        }
                    
                         @include susy-media($xlarge-up){
                            padding:30px 0;
                            padding-left:150px;
                            text-align:left;
                            font-size:$font-size-midextra;
                            line-height:$font-size-midextra;
                        }
               
                 }
                 
            }
        }
}
#block-bean-dla-biznesu-modele-tekst, #block-bean-co-op-models-content, #block-bean-co-op-models-content--2, #block-bean-kooperationsmodelle-content{
    
    @include span(12 break);
    .entity-bean > .content > .field-type-text-long:first-of-type{
        padding:20px;
        @include susy-media($medium-up){
                    padding:20px;
        }
        @include susy-media($large-up){
                     padding:40px;
        }
       
         @include span (isolate 10 at 2 of 12 break);
         @include susy-media($large-up){
                        @include span(isolate 6 at 4 of 12 break);
                   }
    }
    .field-type-entityreference{
        @include span(12 of 12 break);
        .field-items{
            .field-item{
                padding:10px 0;
                @include susy-media($medium-up){
                    padding:20px 0;
                }
                @include susy-media($large-up){
                    padding:40px 0;
                }
                @include span(12 of 12 break);
                &.even{
                    background-color:#f4f4f4;
                }
            }
           .entity-bean>.content>.field-type-text{
               h2{
                   
                   margin-left:0;
                   font-weight:400;
                   font-family: $font-primary;
                   font-size:$font-size-bigger;
                   padding:0;
                   @include susy-media($medium-up){
                       font-size:$font-size-medium;
                       padding:0 0 0 40px;
                   }
                   @include susy-media($large-up){
                       font-size:$font-size-lessextra;
                   }
                   margin:10px 0;
                   
                   text-align:center;
                   @include susy-media($large-up){
                        text-align:right;
                   }
               }
               @include span (isolate 10 at 2 of 12 break);
               @include susy-media($large-up){
                    @include span (isolate 2 at 2 of 12 break);
               }
               font-size:$font-size-lessextra;
               color:$color-blue;
           }
            .entity-bean>.content>.field-type-text-long{ 
                @include span (isolate 10 at 2 of 12 break);
                @include susy-media($large-up){
                        @include span(isolate 7 at 5 of 12 nobreak);
                    }
                        
            }
        }
    }   
}
#block-bean-outsourcing-hr-typy, #block-bean-outsourcing-hr-types, #block-bean-hr-types-de{
  .resp-tab-content{
     ul{
        margin:10px 0;
        padding:0;
        li{
          margin-left:10px;
            background: url(../images/biz/outsourcingit_icon_wypunktowanie.png) no-repeat left top;
            padding: 5px 0px 10px 35px;
            /* reset styles (optional): */
            background-size:20px auto;
            list-style: none;
            margin: 0;
            line-height:140%;
            font-size:$font-size-small;
            @include susy-media($medium-up){
               margin-left:15px;
                padding: 5px 0px 10px 39px;
                background-size:24px auto;
                font-size:$font-size-normal+1;
            }
            @include susy-media($xxlarge-up){
               margin-left:20px;
                padding: 5px 0px 10px 41px;
                background-size:30px auto;
                line-height:180%;
                font-size:$font-size-default;
            }
            
            
        }
    }
  }
  @include span(12 break);
  .resp-tabs-container{
    h2.resp-accordion{
      font-size:$font-size-default;
      color:$color-blue;
      text-align:center;
      padding:10px 0;
      &.resp-tab-active{
        background:$color-blue !important;
        color:#fff;
      }
    }
  }
  .resp-vtabs{
    .resp-tabs-list{
    .resp-tab-item
      {
        padding:20px 20px 20px 120px !important;
        margin:30px 0;
        font-weight:100;
        color:#ccc;
        font-family:$font-primary;
        font-size:$font-size-bigger;
        @include susy-media($xlarge-up){
          font-size:$font-size-morethanbigger;
        }
        @include susy-media($xxlarge-up){
          font-size:$font-size-medium;
        }
        @include susy-media($xxxlarge-up){
          font-size:$font-size-xm;
        }
        &:nth-of-type(1){
           background:url(../images/biz/outsourcinghr_icon_outsourcingkadrowy_unactive.png) no-repeat left 20px center;
        }
        &:nth-of-type(2){
           background:url(../images/biz/outsourcinghr_icon_rekrutacjastala_unactive.png) no-repeat left 20px center;
        }
        &:nth-of-type(3){
           background:url(../images/biz/outsourcinghr_icon_outsourcingprocesow_unactive.png) no-repeat left 20px center;
        }
        &.resp-tab-active
        {
         border:none;
         border-right:4px $color-blue solid;
         color:$color-blue;
         &:nth-of-type(1){
           background:url(../images/biz/outsourcinghr_icon_outsourcingkadrowy_active.png) no-repeat left 20px center;
         }
         &:nth-of-type(2){
           background:url(../images/biz/outsourcinghr_icon_rekrutacjastala_active.png) no-repeat left 20px center;
         }
         &:nth-of-type(3){
           background:url(../images/biz/outsourcinghr_icon_outsourcingprocesow_active.png) no-repeat left 20px center;
         }
        }
     
      }
    }
  }
}
