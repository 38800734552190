@mixin icon($name){
	@extend .#{$fa-css-prefix};
	@extend .#{$fa-css-prefix}-#{$name}:before;
}
@mixin clear {
    &:before, &:after {
        content: " ";
        display: block;
        clear: both;
        width: 100%;
        height: 0;
    }
}
@mixin ctaminheight-twice {
    min-height: $height-cta-full*2;
    @include susy-media($medium-up) { min-height: $height-cta-medium*2};
    @include susy-media($large-up) { min-height: $height-cta-large*2};
    @include susy-media($xlarge-up) { min-height: $height-cta-xlarge*2};
    @include susy-media($xxlarge-up) { min-height: $height-cta-xxlarge*2};
    @include susy-media($xxxlarge-up) { min-height: $height-cta-full*2};  
}
@mixin ctaminheight {
    min-height: $height-cta-full;
    @include susy-media($medium-up) { min-height: $height-cta-medium};
    @include susy-media($large-up) { min-height: $height-cta-large};
    @include susy-media($xlarge-up) { min-height: $height-cta-xlarge};
    @include susy-media($xxlarge-up) { min-height: $height-cta-xxlarge};
    @include susy-media($xxxlarge-up) { min-height: $height-cta-full};  
}
@mixin ctaminheight-half {
    min-height: $height-cta-full/2;
    @include susy-media($medium-up) { min-height: $height-cta-medium/2};
    @include susy-media($large-up) { min-height: $height-cta-large/2};
    @include susy-media($xlarge-up) { min-height: $height-cta-xlarge/2};
    @include susy-media($xxlarge-up) { min-height: $height-cta-xxlarge/2};
    @include susy-media($xxxlarge-up) { min-height: $height-cta-full/2};  
}
@mixin ctaminheight-quarter {
    min-height: $height-cta-full/4;
    @include susy-media($medium-up) { min-height: $height-cta-medium/4};
    @include susy-media($large-up) { min-height: $height-cta-large/4};
    @include susy-media($xlarge-up) { min-height: $height-cta-xlarge/4};
    @include susy-media($xxlarge-up) { min-height: $height-cta-xxlarge/4};
    @include susy-media($xxxlarge-up) { min-height: $height-cta-full/4};
}
@mixin ctaheight-twice {
    height: $height-cta-full*2;
    @include susy-media($medium-up) { height: $height-cta-medium*2};
    @include susy-media($large-up) { height: $height-cta-large*2};
    @include susy-media($xlarge-up) { height: $height-cta-xlarge*2};
    @include susy-media($xxlarge-up) { height: $height-cta-xxlarge*2};
    @include susy-media($xxxlarge-up) { height: $height-cta-full*2};   
}
@mixin ctaheight {
    height: $height-cta-full;
    @include susy-media($medium-up) { height: $height-cta-medium};
    @include susy-media($large-up) { height: $height-cta-large};
    @include susy-media($xlarge-up) { height: $height-cta-xlarge};
    @include susy-media($xxlarge-up) { height: $height-cta-xxlarge};
    @include susy-media($xxxlarge-up) { height: $height-cta-full};   
}
@mixin ctaheight-half {
    height: $height-cta-full/2;
    @include susy-media($medium-up) { height: $height-cta-medium/2};
    @include susy-media($large-up) { height: $height-cta-large/2};
    @include susy-media($xlarge-up) { height: $height-cta-xlarge/2};
    @include susy-media($xxlarge-up) { height: $height-cta-xxlarge/2};
    @include susy-media($xxxlarge-up) { height: $height-cta-full/2};   
}
@mixin ctaheight-quarter {
    height: $height-cta-full/4;
    @include susy-media($medium-up) { height: $height-cta-medium/4};
    @include susy-media($large-up) { height: $height-cta-large/4};
    @include susy-media($xlarge-up) { height: $height-cta-xlarge/4};
    @include susy-media($xxlarge-up) { height: $height-cta-xxlarge/4};
    @include susy-media($xxxlarge-up) { height: $height-cta-full/4};   
}
@mixin ctaheight-halfquarter {
    height: $height-cta-full/8;
    @include susy-media($medium-up) { height: $height-cta-medium/8};
    @include susy-media($large-up) { height: $height-cta-large/8};
    @include susy-media($xlarge-up) { height: $height-cta-xlarge/8};
    @include susy-media($xxlarge-up) { height: $height-cta-xxlarge/8};
    @include susy-media($xxxlarge-up) { height: $height-cta-full/8};   
}
@mixin block-header(){
      color:$color-blue;
        font-family:$font-primary;
        font-weight:$light;
        margin-bottom:10px;
        text-align:center;
        @include span(isolate 10 at 2 of 12 break);
        @include susy-media($medium-up){
            @include span(isolate 8 at 3 of 12 break);
        }
        font-size:$font-size-lessextra;
        @include susy-media($large-up){
            text-align:center;
            font-size:$font-size-extra;
            @include span(isolate 10 at 2 of 12 break);
        }
}
@mixin banner(){
      h2.title{
        display:none;
    }
    .field-type-text{
        font-size:$font-size-bigger;
         @include susy-media($small-up){
            font-size:$font-size-medium;
        }
        @include susy-media($medium-up){
            font-size:$font-size-lessextra;
        }
         @include susy-media($large-up){
            font-size:$font-size-extraxl;
        }
    } 
    @include span(12 break);
    img{
        width:100%;
        height:auto;
    }
}
